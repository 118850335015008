<template>
  <div class="recovery">
    <SkeletonAnimate
      :visible="preloader"
    />

    <h2>
      {{ $t('views.auth.password-recovery.password-recovery') }}
    </h2>

    <p>
      {{ $t('views.auth.password-recovery.description') }}
    </p>

    <form @submit.prevent="submit">
      <InputPassword
        :placeholder="`${$t('views.auth.password-recovery.password')}*`"
        :progress="_passwordProgression"
        :is-progress-error="_isErrorPasswordProgress"
        :errors="form.errors.password"
        v-model="form.password"
      />

      <InputPassword
        style="margin: 15px 0 20px;"
        :placeholder="`${$t('views.auth.password-recovery.repeat-password')}*`"
        :errors="form.errors.repeat_password"
        v-model="form.repeat_password"
      />

      <BlueButton
        class="recovery__button"
        type="submit"
        :loading="loading"
        :text="$t('views.auth.password-recovery.change-password')"
        :disabled="loading"
      />
    </form>
  </div>

  <AuthSupport />
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import vClickOutside from 'click-outside-vue3'

import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import InputPassword from '@/ui/inputs/Password.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import AuthSupport from '@/ui/AuthSupport.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    SkeletonAnimate,
    BlueButton,
    InputPassword,
    AuthSupport
  },
  data () {
    return {
      preloader: true,
      loading: false,
      v: useVuelidate(),
      form: {
        password: '',
        repeat_password: '',

        errors: {
          password: [],
          repeat_password: []
        }
      }
    }
  },
  validations () {
    return {
      form: {
        password: { required, minLength: minLength(6) },
        repeat_password: { required, minLength: minLength(6) }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    _passwordProgression () {
      const pass = this.form?.password ? this.form.password : ''
      const value = []

      // const checkName = pass?.toLowerCase()
      //   .indexOf(this.form.name?.replace(/\s+/g, '').toLowerCase()) !== -1

      // const splitEmail = this.form.email?.split('@')
      // const checkEmail = pass
      //   .toLowerCase()
      //   .indexOf(typeof splitEmail === 'object' && splitEmail.length > 0
      //     ? splitEmail[0].toLowerCase() : this.form.email?.toLowerCase()) !== -1

      // if (checkName || checkEmail) {
      //   value.push({
      //     icon: 'error',
      //     text: 'errors.password-progress-no-copy'
      //   })
      // } else {
      //   value.push({
      //     icon: 'success',
      //     text: 'errors.password-progress-no-copy'
      //   })
      // }

      value.push({
        icon: 'success',
        text: 'errors.password-progress-no-copy'
      })

      if (!(/[a-zA-Z]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-letter'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-letter'
        })
      }

      if (!(/[A-Z]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-letter-upper'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-letter-upper'
        })
      }

      if (!(/[0-9]/.test(pass))) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-number'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-number'
        })
      }

      if (pass.length < 6) {
        value.push({
          icon: 'error',
          text: 'errors.password-progress-min'
        })
      } else {
        value.push({
          icon: 'success',
          text: 'errors.password-progress-min'
        })
      }

      return value
    },
    _isErrorPasswordProgress () {
      var count = 0

      for (const i in this._passwordProgression) {
        if (this._passwordProgression[i].icon === 'error') {
          count += 1
        }
      }

      return count > 0
    }
  },
  methods: {
    async fetch () {
      try {
        await this.$axios.get(
          `/auth/reset-password/${this.$route.params.code}`)
      } catch (err) {
        this.$router.push({
          name: 'SignIn',
          query: this.$route.query
        })
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },

    async submit () {
      this.loading = true
      this.v.form.$touch()
      this._setErrors()

      if (this._countError() === 0) {
        const formData = new FormData()
        formData.append('password', this.form.password)
        formData.append('password_confirm', this.form.repeat_password)

        try {
          const resp = await this.$axios.post(
            `/auth/update-password/${this.$route.params.code}`, formData)

          if (resp.status === 200) {
            this.$router.push({
              name: 'SignIn',
              query: this.$route.query
            })
          }
        } catch (err) {
          throw new Error(err)
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    _setErrors () {
      const errorsForm = this.v.form
      const form = this.form
      this.form.errors = {
        password: [],
        repeat_password: []
      }

      // Password
      if (errorsForm.password.required.$invalid) {
        this.form.errors.password.push('errors.required')
      }

      // Repeat password
      if (errorsForm.repeat_password.required.$invalid) {
        this.form.errors.repeat_password.push('errors.required')
      }
      if (form.repeat_password !== form.password) {
        this.form.errors.repeat_password.push('errors.password')
      }
    },
    _countError () {
      const errors = this.form.errors
      var count = 0

      count += errors.password.length +
        errors.repeat_password.length
      return count
    }
  }
}
</script>

<style lang="scss" scoped>
.recovery {
  position: relative;
  width: 100%;
  max-width: 430px;
  margin-top: 50px;
  padding: 20px 20px 30px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;
  overflow: hidden;

  h2 {
    color: rgb(32, 37, 57);
    line-height: 100%;
    font-family: SemiBold;
  }

  p {
    margin: 20px 0;
    color: rgb(32, 37, 57);
    font-family: Medium;
    font-size: 14px;
    line-height: 100%;
  }

  .recovery__button {
    margin: 20px 0 0;
  }
}
</style>
